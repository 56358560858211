import React, { useEffect } from "react";

import classNames from "classnames";
import { Formik, Form, Field } from "formik";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";

import {
  Section,
  Button,
  Spinner,
  TextFormField,
  Text,
} from "../../../components";
import { VALIDATION_MESSAGES, MAIN_SITE } from "../../../constants";
import { profileLinkRegex } from "../../../constants/regexes";
import { setProfile, useUser, useUserDispatch } from "../../../context";
import { useProfileDataQuery } from "../../auth/queries";
import { useUpdateProfileQuery } from "../../settings/queries";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { Flex } from "components/Warpper";
import { theme } from "ovou-ui";

import { useClipboard } from "@chakra-ui/react";

const validationSchema = yup.object({
  bio: yup.string(),
  handle: yup
    .string()
    .min(3, VALIDATION_MESSAGES.MIN_LENGTH("Profile URL", 3))
    .matches(profileLinkRegex, VALIDATION_MESSAGES.PROFILE_URL)
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Profile URL")),
});

const ProfileForm = () => {
  const dispatch = useUserDispatch();
  const track = useTracking();
  const { addToast } = useToasts();
  const [{ currentProfile }] = useUser();
  const { getProfileData } = useProfileDataQuery({});
  const { hasCopied, onCopy, setValue } = useClipboard(
    `${MAIN_SITE}/${currentProfile?.handle}`,
  );

  useEffect(() => {
    setValue(`${MAIN_SITE}/${currentProfile?.handle}`);
  }, [currentProfile.handle, setValue]);

  const { mutate: updateProfile, status } = useUpdateProfileQuery({
    showToasts: false,
    onSuccess: async () => {
      const data = await getProfileData();
      dispatch(setProfile(data.profiles[0]));
      addToast("Success", {
        appearance: "success",
      });
    },
  });
  const isLoading = status === "loading";

  const handleSubmit = values => {
    updateProfile(
      {
        profileData: {
          ...values,
          logo: "",
          background: "",
          handle: values.handle.toLowerCase(),
        },
        handle: currentProfile?.handle,
      },
      {
        onError: error => {
          const errorMessage =
            typeof error === "string"
              ? error
              : error?.response?.data?.detail ||
                error?.response?.data?.message ||
                error?.message ||
                "Failed to update profile, please contact support for assistance.";
          addToast(errorMessage, {
            appearance: "error",
          });
        },
        onSuccess: () => {
          track({
            eventName: ANALYTICS_EVENTS.COMPANY_PROFILE_BUSINESS_PAGE_URL,
          });
        },
      },
    );
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        bio: currentProfile?.bio || "",
        handle: `${currentProfile?.handle || ""}`,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form autoComplete="off">
          <Section label="Business Page URL" showBorder>
            <Flex alignItems="end">
              <Field
                component={TextFormField}
                label="Profile URL"
                name="handle"
                preVal={`${MAIN_SITE}/`}
              />
              <Text
                color={theme.palette.brand.primary.orange}
                fontWeight="800"
                onClick={onCopy}
              >
                {hasCopied ? "Copied" : "Copy"}
              </Text>
            </Flex>
          </Section>
          <div
            className={classNames({
              "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center mt-6 sm:mt-5 md:mt-12": true,
            })}
          >
            <div className="col-start-2">
              <Button
                disabled={isLoading || !isValid || !dirty}
                size="small"
                block
                data-testid="company-profile-profile-save-button"
              >
                {isLoading ? <Spinner color="white" /> : "Save Changes"}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
